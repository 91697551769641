var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"添加资料","footer":null},on:{"cancel":function($event){_vm.visible = false}}},[_c('a-form',{attrs:{"form":_vm.formModel,"colon":false},on:{"submit":_vm.addResource}},[_c('a-form-item',{attrs:{"label":"资料名称","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"资料数量","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'count',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'count',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("关闭")])],1)],1)],1)],1),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"接收人"}},[_c('EmployeeSelector',{staticStyle:{"width":"100%"},attrs:{"value":_vm.master.name ? [_vm.master] : []},on:{"change":(arr) => {
                    if (arr.length > 0) {
                      _vm.master = arr[0];
                    } else {
                      _vm.master = {};
                    }
                  }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.master.name)?_c('div',[_vm._v(" "+_vm._s(_vm.master.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"接收部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'deptId',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'deptId',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption},model:{value:(_vm.form.deptId),callback:function ($$v) {_vm.$set(_vm.form, "deptId", $$v)},expression:"form.deptId"}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"发放时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'grantTime',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'grantTime',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1)],1),_c('div',{staticClass:"between",staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticClass:"title"},[_vm._v("发放资料情况")]),_c('a-button',{staticStyle:{"background":"#ff8c18","border":"1px solid #ff8c18"},attrs:{"type":"primary","icon":"plus"},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" 添加 ")])],1),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"资料名称","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"资料数量","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(text.count))])]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v(" 移除 ")])]}}])})],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }