<template>
  <div>
    <Pane />
    <a-modal
      :visible="visible"
      title="添加资料"
      :footer="null"
      @cancel="visible = false"
    >
      <a-form :form="formModel" :colon="false" @submit="addResource">
        <a-form-item
          label="资料名称"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
        <a-input
            style="width: 100%"
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
      </a-form-item>

        <a-form-item
          label="资料数量"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input-number
            :min="0"
            style="width: 100%"
            v-decorator="[
              'count',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button type="primary" html-type="submit">保存</a-button>
            <a-button @click="visible = false">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>

          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="接收人">
                <EmployeeSelector
                  style="width: 100%"
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        master = arr[0];
                      } else {
                        master = {};
                      }
                    }
                  "
                  :value="master.name ? [master] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="master.name">
                      {{ master.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </a-form-item>
            </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="接收部门">
                <a-select
                v-model="form.deptId"
                show-search
                :filter-option="$selectFilterOption"
                style="width: 100%"
                v-decorator="[
                  'deptId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="发放时间">
              <a-date-picker
                placeholder=""
                v-decorator="[
                  'grantTime',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="between" style="margin-bottom: 5px;">
          <div class="title">发放资料情况</div>
          <a-button
            @click="visible = true"
            type="primary"
            style="background: #ff8c18; border: 1px solid #ff8c18"
            icon="plus"
          >
            添加
          </a-button>
        </div>
        <a-table bordered :dataSource="list" :pagination="false">
          <a-table-column title="资料名称" data-index="name"> </a-table-column>
          <a-table-column title="资料数量" align="center">
            <template slot-scope="text">
              <span style="color: #1890ff">{{ text.count }}</span>
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center" width="100px">
            <template slot-scope="text, record, index">
              <a href="#" class="danger" @click.prevent="remove(index)">
                移除
              </a>
            </template>
          </a-table-column>
        </a-table>
        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import EmployeeSelector from "@/components/employee-selector";

import request from "@/api/request";

function add(data) {
  return request({
    url: "/office-service/technicalInformationGrant/add",
    method: "post",
    data
  });
}

export default {
  name: "courseAdd",

  mixins: [organization],
  components: {
    EmployeeSelector,
  },
  data() {
    return {
      visible: false,
      master: {},
      form: this.$form.createForm(this),
      formModel: this.$form.createForm(this),
      cover: {},
      chapters: [],
      list: [],
      loading: false
    };
  },

  methods: {
    addResource(e) {
      e.preventDefault();
      this.formModel.validateFields((err, values) => {
        if (!err) {
          this.list.push(values);
          this.visible = false;
          this.formModel.resetFields();
        }
      });
    },
    remove(index) {
      this.list.splice(index, 1);
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.master.name) {
            this.$message.error("请选择接收人！");
            return;
          }
          if(this.list.length < 1) {
            this.$message.error("请添加资料！");
            return;
          }
          this.loading = true;
          add({
            ...values,
            receiveId: this.master.userId,
            receiveName: this.master.name,
            deptName: this.organizationList.filter(v=>v.id == values.deptId)[0].uniqueName,
            dataList: this.list
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.loading = false;
            });

         
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}

.center {
  padding: 40px;
}
</style>
